import * as _react2 from "react";
var _react = "default" in _react2 ? _react2.default : _react2;
import * as _canvas2 from "@rive-app/canvas";
var _canvas = "default" in _canvas2 ? _canvas2.default : _canvas2;
var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: !0
});
var e = _react,
  n = _canvas;
function t(e) {
  return e && "object" == typeof e && "default" in e ? e : {
    default: e
  };
}
var r = t(e),
  i = function () {
    return i = Object.assign || function (e) {
      for (var n, t = 1, r = arguments.length; t < r; t++) for (var i in n = arguments[t]) Object.prototype.hasOwnProperty.call(n, i) && (e[i] = n[i]);
      return e;
    }, i.apply(this || _global, arguments);
  };
function a(e, n) {
  var t = {};
  for (var r in e) Object.prototype.hasOwnProperty.call(e, r) && n.indexOf(r) < 0 && (t[r] = e[r]);
  if (null != e && "function" == typeof Object.getOwnPropertySymbols) {
    var i = 0;
    for (r = Object.getOwnPropertySymbols(e); i < r.length; i++) n.indexOf(r[i]) < 0 && Object.prototype.propertyIsEnumerable.call(e, r[i]) && (t[r[i]] = e[r[i]]);
  }
  return t;
}
function o() {
  var e = "undefined" != typeof window && "number" == typeof window.devicePixelRatio ? window.devicePixelRatio : 1;
  return Math.min(Math.max(1, e), 3);
}
var s = function () {
  function e() {}
  return e.prototype.observe = function () {}, e.prototype.unobserve = function () {}, e.prototype.disconnect = function () {}, e;
}();
var u = globalThis.ResizeObserver || s,
  c = void 0 !== globalThis.ResizeObserver,
  f = !c;
function d(n, t) {
  void 0 === t && (t = !0);
  var r = e.useState({
      width: 0,
      height: 0
    }),
    i = r[0],
    a = r[1];
  e.useEffect(function () {
    if ("undefined" != typeof window && t) {
      var e = function () {
        a({
          width: window.innerWidth,
          height: window.innerHeight
        });
      };
      return f && (e(), window.addEventListener("resize", e)), function () {
        return window.removeEventListener("resize", e);
      };
    }
  }, []);
  var o,
    s,
    d,
    l = e.useRef(new u((o = function (e) {
      c && a({
        width: e[e.length - 1].contentRect.width,
        height: e[e.length - 1].contentRect.height
      });
    }, s = 0, d = 0, function () {
      for (var e = this || _global, n = [], t = 0; t < arguments.length; t++) n[t] = arguments[t];
      clearTimeout(d), d = window.setTimeout(function () {
        return o.apply(e, n);
      }, s);
    })));
  return e.useEffect(function () {
    var e = l.current;
    if (t) {
      var r = n.current;
      return n.current && c && e.observe(n.current), function () {
        e.disconnect(), r && c && e.unobserve(r);
      };
    }
    e.disconnect();
  }, [n, l]), i;
}
var l = {
  useDevicePixelRatio: !0,
  fitCanvasToArtboardHeight: !1,
  useOffscreenRenderer: !0,
  shouldResizeCanvasToContainer: !0
};
function v(e) {
  return Object.assign({}, l, e);
}
function h(n) {
  var t = n.riveLoaded,
    r = void 0 !== t && t,
    i = n.canvasRef,
    a = n.containerRef,
    s = n.options,
    u = void 0 === s ? {} : s,
    c = n.onCanvasHasResized,
    f = n.artboardBounds,
    l = v(u),
    h = e.useState({
      height: 0,
      width: 0
    }),
    p = h[0],
    w = p.height,
    R = p.width,
    g = h[1],
    b = e.useState({
      height: 0,
      width: 0
    }),
    y = b[0],
    m = y.height,
    C = y.width,
    O = b[1],
    x = e.useState(!0),
    E = x[0],
    P = x[1],
    T = l.fitCanvasToArtboardHeight,
    L = l.shouldResizeCanvasToContainer,
    z = l.useDevicePixelRatio,
    j = d(a, L),
    M = function () {
      var n = o(),
        t = e.useState(n),
        r = t[0],
        i = t[1];
      return e.useEffect(function () {
        if ("undefined" != typeof window && "matchMedia" in window) {
          var e = function () {
              var e = o();
              i(e);
            },
            n = window.matchMedia("screen and (resolution: ".concat(r, "dppx)"));
          return n.hasOwnProperty("addEventListener") ? n.addEventListener("change", e) : n.addListener(e), function () {
            n.hasOwnProperty("removeEventListener") ? n.removeEventListener("change", e) : n.removeListener(e);
          };
        }
      }, [r]), r;
    }(),
    S = null != f ? f : {},
    H = S.maxX,
    k = S.maxY,
    D = e.useCallback(function () {
      var e,
        n,
        t,
        r,
        i = null !== (n = null === (e = a.current) || void 0 === e ? void 0 : e.clientWidth) && void 0 !== n ? n : 0,
        o = null !== (r = null === (t = a.current) || void 0 === t ? void 0 : t.clientHeight) && void 0 !== r ? r : 0;
      return T && f ? {
        width: i,
        height: i * (f.maxY / f.maxX)
      } : {
        width: i,
        height: o
      };
    }, [a, T, H, k]);
  e.useEffect(function () {
    if (L && a.current && r) {
      var e = D(),
        n = e.width,
        t = e.height,
        o = !1;
      if (i.current) {
        var s = n !== R || t !== w;
        if (l.fitCanvasToArtboardHeight && s && (a.current.style.height = t + "px", o = !0), l.useDevicePixelRatio) {
          if (s || n * M !== C || t * M !== m) {
            var u = M * n,
              f = M * t;
            i.current.width = u, i.current.height = f, i.current.style.width = n + "px", i.current.style.height = t + "px", O({
              width: u,
              height: f
            }), o = !0;
          }
        } else s && (i.current.width = n, i.current.height = t, O({
          width: n,
          height: t
        }), o = !0);
        g({
          width: n,
          height: t
        });
      }
      c && (E || o) && c && c(), E && P(!1);
    }
  }, [i, a, j, M, D, E, P, m, C, w, R, c, L, T, z, r]);
}
function p(e) {
  var n = e.setContainerRef,
    t = e.setCanvasRef,
    o = e.className,
    s = void 0 === o ? "" : o,
    u = e.style,
    c = e.children,
    f = a(e, ["setContainerRef", "setCanvasRef", "className", "style", "children"]),
    d = i({
      width: "100%",
      height: "100%"
    }, u);
  return r.default.createElement("div", i({
    ref: n,
    className: s
  }, !s && {
    style: d
  }), r.default.createElement("canvas", i({
    ref: t,
    style: {
      verticalAlign: "top",
      width: 0,
      height: 0
    }
  }, f), c));
}
function w(t, a) {
  void 0 === a && (a = {});
  var o = e.useRef(null),
    s = e.useRef(null),
    u = e.useState(null),
    c = u[0],
    f = u[1],
    d = Boolean(t),
    l = v(a),
    w = e.useCallback(function () {
      c && (c.startRendering(), c.resizeToCanvas());
    }, [c]);
  h({
    riveLoaded: !!c,
    canvasRef: o,
    containerRef: s,
    options: l,
    onCanvasHasResized: w,
    artboardBounds: null == c ? void 0 : c.bounds
  });
  var R = e.useCallback(function (e) {
      if (e && t && d) {
        var r = l.useOffscreenRenderer,
          a = new n.Rive(i(i({
            useOffscreenRenderer: r
          }, t), {
            canvas: e
          }));
        a.on(n.EventType.Load, function () {
          o.current ? f(a) : a.cleanup();
        });
      } else null === e && o.current && (o.current.height = 0, o.current.width = 0);
      o.current = e;
    }, [d]),
    g = e.useCallback(function (e) {
      s.current = e;
    }, []);
  e.useEffect(function () {
    var e = new IntersectionObserver(function (e) {
      e[0].isIntersecting ? c && c.startRendering() : c && c.stopRendering();
    });
    return o.current && e.observe(o.current), function () {
      e.disconnect();
    };
  }, [c]), e.useEffect(function () {
    return function () {
      c && (c.cleanup(), f(null));
    };
  }, [c]);
  var b = null == t ? void 0 : t.animations;
  e.useEffect(function () {
    c && b && (c.isPlaying ? (c.stop(c.animationNames), c.play(b)) : c.isPaused && (c.stop(c.animationNames), c.pause(b)));
  }, [b, c]);
  var y = e.useCallback(function (e) {
    return r.default.createElement(p, i({
      setContainerRef: g,
      setCanvasRef: R
    }, e));
  }, [R, g]);
  return {
    canvas: o.current,
    container: s.current,
    setCanvasRef: R,
    setContainerRef: g,
    rive: c,
    RiveComponent: y
  };
}
exports.default = function (e) {
  var n = e.src,
    t = e.artboard,
    o = e.animations,
    s = e.stateMachines,
    u = e.layout,
    c = e.useOffscreenRenderer,
    f = void 0 === c || c,
    d = e.shouldDisableRiveListeners,
    l = void 0 !== d && d,
    v = e.shouldResizeCanvasToContainer,
    h = void 0 === v || v,
    p = e.children,
    R = a(e, ["src", "artboard", "animations", "stateMachines", "layout", "useOffscreenRenderer", "shouldDisableRiveListeners", "shouldResizeCanvasToContainer", "children"]),
    g = w({
      src: n,
      artboard: t,
      animations: o,
      layout: u,
      stateMachines: s,
      autoplay: !0,
      shouldDisableRiveListeners: l
    }, {
      useOffscreenRenderer: f,
      shouldResizeCanvasToContainer: h
    }).RiveComponent;
  return r.default.createElement(g, i({}, R), p);
}, exports.useResizeCanvas = h, exports.useRive = w, exports.useStateMachineInput = function (t, r, i, a) {
  var o = e.useState(null),
    s = o[0],
    u = o[1];
  return e.useEffect(function () {
    function e() {
      if (t && r && i || u(null), t && r && i) {
        var e = t.stateMachineInputs(r);
        if (e) {
          var n = e.find(function (e) {
            return e.name === i;
          });
          void 0 !== a && n && (n.value = a), u(n || null);
        }
      } else u(null);
    }
    e(), t && t.on(n.EventType.Play, function () {
      e();
    });
  }, [t]), s;
}, Object.keys(n).forEach(function (e) {
  "default" === e || exports.hasOwnProperty(e) || Object.defineProperty(exports, e, {
    enumerable: !0,
    get: function () {
      return n[e];
    }
  });
});
export default exports;
export const __esModule = exports.__esModule,
  useResizeCanvas = exports.useResizeCanvas,
  useRive = exports.useRive,
  useStateMachineInput = exports.useStateMachineInput;